/* Main
================================================== */
main {
  padding: 4rem 0;
  background-color: $light-grey;
  @include bp(medium) {
    padding: 6rem 0;
  }
  .wf-columns {
    gap: 0 4rem;
  }
}
.main {
  &--full-width {
    position: relative;
    padding: 0;
    .wf-columns {
      gap: 0;
    }
    &-pattern-1 {
      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 10;
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &::before {
        top: 0;
        left: 0;
        background-image: url('#{$image-directory}corner-top-left-primary.svg');
      }
      &::after {
        bottom: 0;
        right: 0;
        background-image: url('#{$image-directory}corner-bottom-right-secondary.svg');
      }
    }
  }
  &--white {
    background-color: $white;
  }
  &--colour {
    &-1,
    &-2,
    &-3 {
      * {
        color: $white;
      }
    }
    &-3 {
      p,
      li {
        font-weight: 500;
      }
    }
    &-1 {
      background-color: $primary-colour;
    }
    &-2 {
      background-color: $secondary-colour;
    }
    &-3 {
      background-color: $tertiary-colour;
    }
    &-4 {
      background-color: $quaternary-colour;
    }
  }
}

.label-warning {
  display: none;
}

.wf-column {
  position: relative;
}

.fields-container {
  margin: 0;
  ul {
    margin: 0;
    list-style: none;
  }
}

.list-style {
  &--1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    text-transform: uppercase;
    li {
      margin: 0 0 0 2rem;
      font-size: 1.2rem;
      text-align: center;
    }
  }
}

.box-shadow {
  &-style-1 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  }
  &-style-2 {
    box-shadow: 12px 12px 24px 0px rgba(108, 54, 14, 0.5);
  }
}

.image-overlay {
  position: absolute;
  @extend .box-shadow-style-1;
  &--1 {
    top: 10rem;
  }
}

.wf-columns-layout-1-1 {
  .wf-column {
    flex: 0 0 100 !important;
    max-width: 100% !important;
    position: relative;
    @include bp(large) {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }
}

.half-containers {
  @include bp(large) {
    flex: 0 0 100%;
    max-height: 50%;
    min-height: 50%;
    max-width: 100%;
  }
}

.full-width-row {
  display: flex;
  gap: 0;
  position: relative;
  &--black,
  &--bg-1,
  &--bg-2,
  &--bg-3,
  &--bg-4 {
    * {
      color: $white;
    }
  }
  &--black {
    background-color: $black;
  }
  &--bg-1 {
    background-color: $primary-colour;
  }
  &--bg-2 {
    background-color: $secondary-colour;
  }
  &--bg-3 {
    background-color: $tertiary-colour;
  }
  &--bg-4 {
    background-color: $quaternary-colour;
  }
  .row {
    &__content {
      z-index: 20;
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      padding: 4rem 2.5rem;
      @include bp(large) {
        padding: 6rem;
      }
      * {
        width: 100%;
      }
      .button {
        width: auto;
      }
    }
    &__image {
      figure {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100% !important;
          max-width: none;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
    &__map {
      p {
        margin: 0;
      }
      .embed-container {
        height: 100%;
      }
    }
  }
  .column--padding {
    padding: 4rem 2.5rem;
    @include bp(large) {
      padding: 6rem;
    }
  }
}

// Accordion
.accordion {
  padding: 0;
  background: transparent !important;
  &-item {
    margin-bottom: 0;
    &.is-active {
      .accordion-title {
        background-color: darken($medium-grey, 8%);
        &:before {
          color: $secondary-colour;
        }
      }
    }
  }
  &-title,
  &-content {
    overflow: hidden;
    margin-bottom: 0.25rem;
    border-radius: 0px !important;
    border: 0 !important;
  }
  &-title {
    background-color: $medium-grey;
    color: $white;
    &:hover {
      background-color: darken($medium-grey, 8%);
      &:before {
        color: $secondary-colour;
      }
    }
    &:before {
      margin-top: -0.7rem;
      font-size: 1.4rem;
    }
    * {
      margin: 0;
      font-size: 1.2rem;
      font-family: $primary-font;
      font-weight: bold;
    }
  }
  &-content {
    padding-top: 2rem;
  }
}
